
    import { Vue, Component } from 'vue-property-decorator';
    import { RouteNames } from '@/enums/routes/RouteNames';

    @Component({
        name: 'ErrorView',
    })
    export default class ErrorView extends Vue {
        private goToHome() {
            this.$router.push({name: RouteNames.home});
        }
    }
